import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import Logo from 'ui-component/Logo';
import { MENU_OPEN, SET_ROUTE, SET_MODAL } from 'store/actions';

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const defaultId = useSelector((state) => state.customization.defaultId);
  const savedState = useSelector((state) => state.customization.contentsSaved);
  const dispatch = useDispatch();

  const handleRoute = () => {
    if ((pathname === '/contents/record-write' || pathname === '/ps/ps-write') && !savedState) {
      dispatch({ type: SET_ROUTE, lastRoute: config.defaultPath });
      dispatch({ type: SET_MODAL, modalOpened: true });
      return;
    }
    dispatch({ type: MENU_OPEN, id: defaultId });
    navigate(config.defaultPath);
  };
  return (
    <ButtonBase disableRipple onClick={handleRoute}>
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;
