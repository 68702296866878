// react
import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';

// material-ui
import { useTheme, styled } from '@mui/material/styles';
import { Divider, Grid, List, ListItem, ListItemAvatar, Typography } from '@mui/material';
import { READ_NOTI } from 'store/actions';

// styles
const ListItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList = () => {
  const theme = useTheme();
  const notiArr = useSelector((state) => state.noti.notificationList);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleNotificationClick = (notiInfo) => {
    const { click_action } = notiInfo;
    dispatch({ type: READ_NOTI, notiInfo });
    navigate(click_action);
  };

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      {notiArr.length === 0 ? (
        <ListItemWrapper>
          <Typography variant="subtitle2">새로운 알림이 없습니다.</Typography>
        </ListItemWrapper>
      ) : (
        notiArr.map((notiInfo, index) => {
          const { title, content, image } = notiInfo;
          return (
            <React.Fragment key={index}>
              <ListItemWrapper onClick={() => handleNotificationClick(notiInfo)}>
                <ListItem sx={{ display: 'flex', alignItems: 'flex-start' }}>
                  <ListItemAvatar>
                    <img src={image} alt="icon" width={42} height={42} />
                  </ListItemAvatar>
                  <Grid container direction="column">
                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <Typography variant="title">{title}</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ pb: 2 }}>
                      <Typography variant="subtitle2">{content}</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              </ListItemWrapper>
              <Divider />
            </React.Fragment>
          );
        })
      )}
    </List>
  );
};

export default NotificationList;
