// action - customization reducer
export const SET_MENU = '@customization/SET_MENU';
export const MENU_TOGGLE = '@customization/MENU_TOGGLE';
export const MENU_OPEN = '@customization/MENU_OPEN';
export const SET_FONT_FAMILY = '@customization/SET_FONT_FAMILY';
export const SET_BORDER_RADIUS = '@customization/SET_BORDER_RADIUS';
export const SET_MODAL = '@customization/SET_MODAL';
export const SET_SAVED = '@customization/SET_SAVED';
export const SET_ROUTE = '@customization/SET_ROUTE';

// action - auth reducer
export const SIGN_IN = '@auth/SIGN_IN';
export const SIGN_OUT = '@auth/SIGN_OUT';
export const REFRESH_TOKEN = '@auth/REFRESH_TOKEN';

// action - noti reducer
export const GET_NOTI = '@noti/GET_NOTI';
export const READ_NOTI = '@noti/READ_NOTI';

// action - snackBar reducer
export const OPEN_SNACK = '@snackBar/OPEN_SNACK';
export const CLOSE_SNACK = '@snackBar/CLOSE_SNACK';

// action - modal reducer
export const OPEN_MODAL = '@modal/OPEN_MODAL';
export const CLOSE_MODAL = '@modal/CLOSE_MODAL';
