// react
import { useSelector, useDispatch } from 'react-redux';

// mui
import { Snackbar, Alert, Grow } from '@mui/material';
import { CLOSE_SNACK } from 'store/actions';

function GrowTransition(props) {
  return <Grow {...props} />;
}

const CustomSanckbar = () => {
  const dispatch = useDispatch();
  const snackState = useSelector((state) => state.snackBar);
  const { open, severity, message } = snackState;

  const handleClose = () => {
    dispatch({ type: CLOSE_SNACK });
  };
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={open}
      autoHideDuration={5000}
      TransitionComponent={GrowTransition}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSanckbar;
