import dashboard from './dashboard';
import manage from './manage';
import contents from './contents';
import database from './database';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [dashboard, manage, contents, database]
};

export default menuItems;
