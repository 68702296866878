import * as actionTypes from '../actions';

const initialState = {
  uid: '',
  accessToken: '',
  email: ''
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SIGN_IN:
      return {
        ...state,
        uid: action.uid,
        accessToken: action.accessToken,
        email: action.email
      };
    case actionTypes.SIGN_OUT:
      return {
        ...state,
        uid: '',
        accessToken: '',
        email: ''
      };
    case actionTypes.REFRESH_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      };
    default:
      return state;
  }
};

export default authReducer;
