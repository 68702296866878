// assets
import { IconUsers, IconHelp, IconInfoSquareRounded } from '@tabler/icons';

// constant
const icons = {
  IconUsers,
  IconInfoSquareRounded,
  IconHelp
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const manage = {
  id: 'manage',
  title: 'manage',
  type: 'group',
  children: [
    {
      id: 'manage-usermanage',
      title: '유저 관리',
      type: 'item',
      url: '/manage/user',
      icon: icons.IconUsers,
      breadcrumbs: false
    },
    {
      id: 'manage-notice',
      title: '공지사항 관리',
      type: 'item',
      url: '/manage/notice',
      icon: icons.IconInfoSquareRounded,
      breadcrumbs: false
    },
    {
      id: 'manage-help',
      title: '문의사항 관리',
      type: 'item',
      url: '/manage/help',
      icon: icons.IconHelp,
      breadcrumbs: false
    }
  ]
};

export default manage;
