import * as actionTypes from '../actions';

const initialState = {
  open: false,
  severity: 'success',
  title: '',
  content: '',
  callback: null
};

const modalReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OPEN_MODAL:
      return {
        ...state,
        open: true,
        severity: action.severity,
        title: action.title,
        content: action.content,
        callback: action.callback
      };
    case actionTypes.CLOSE_MODAL:
      return {
        ...state,
        open: false,
        callback: null
      };
    default:
      return state;
  }
};

export default modalReducer;
