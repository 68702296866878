// react
import * as React from 'react';

// mui
import { Backdrop, Box, Modal, Fade, Typography, Button } from '@mui/material';

// redux
import { useDispatch, useSelector } from 'react-redux';
import { CLOSE_MODAL } from 'store/actions';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

const MainModal = () => {
  const dispatch = useDispatch();
  const modalState = useSelector((state) => state.modal);
  const { open, severity, title, content, callback } = modalState;

  const handleModalClose = () => {
    dispatch({ type: CLOSE_MODAL, open: false });
  };

  const handleModalConfirm = () => {
    if (callback) {
      callback();
    }
    dispatch({ type: CLOSE_MODAL, open: false });
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={open}
      onClose={handleModalClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500
        }
      }}
    >
      <Fade in={open}>
        <Box sx={style}>
          <Typography id="transition-modal-title" sx={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
            {title}
          </Typography>
          <Typography id="transition-modal-description" sx={{ mt: 2 }}>
            {content}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'end', gap: '8px' }}>
            {severity === 'warning' ? (
              <>
                <Button variant="outlined" onClick={handleModalClose}>
                  취소
                </Button>
                <Button variant="outlined" onClick={handleModalConfirm}>
                  확인
                </Button>
              </>
            ) : (
              <Button variant="outlined" onClick={handleModalConfirm}>
                확인
              </Button>
            )}
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

export default MainModal;
