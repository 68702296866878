import { combineReducers } from 'redux';

// reducer import
import customizationReducer from './customizationReducer';
import authReducer from './authReducer';
import notiReducer from './notiReducer';
import snackBarReducer from './snackBarReducer';
import modalReducer from './modalReducer';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  customization: customizationReducer,
  auth: authReducer,
  noti: notiReducer,
  snackBar: snackBarReducer,
  modal: modalReducer
});

export default reducer;
