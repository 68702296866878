import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));

// 실제 사용할 것
const ContentsRecordManage = Loadable(lazy(() => import('views/contents/record-manage')));
const ContentsRecordWrite = Loadable(lazy(() => import('views/contents/record-write')));
const ContentsRecordPreview = Loadable(lazy(() => import('views/contents/record-preview')));
const CommentManage = Loadable(lazy(() => import('views/contents/comment-manage')));

const PS_Manage = Loadable(lazy(() => import('views/ps/ps-manage')));
const PS_Write = Loadable(lazy(() => import('views/ps/ps-write')));
const PS_Preview = Loadable(lazy(() => import('views/ps/ps-preview')));

const ManageUser = Loadable(lazy(() => import('views/manage/usermange')));
const ManageNotice = Loadable(lazy(() => import('views/manage/notice')));
const ManageHelp = Loadable(lazy(() => import('views/manage/help')));

const PatentDatabase = Loadable(lazy(() => import('views/database/patent')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/',
  element: <MainLayout />,
  children: [
    {
      path: '/',
      element: <DashboardDefault />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'contents',
      children: [
        {
          path: 'record-manage',
          element: <ContentsRecordManage />
        },
        {
          path: 'record-write',
          element: <ContentsRecordWrite />
        },
        {
          path: 'record-preview',
          element: <ContentsRecordPreview />
        },
        {
          path: 'comment-manage',
          element: <CommentManage />
        }
      ]
    },
    {
      path: 'ps',
      children: [
        {
          path: 'ps-manage',
          element: <PS_Manage />
        },
        {
          path: 'ps-write',
          element: <PS_Write />
        },
        {
          path: 'ps-preview',
          element: <PS_Preview />
        }
      ]
    },
    {
      path: 'manage',
      children: [
        {
          path: 'user',
          element: <ManageUser />
        },
        {
          path: 'notice',
          element: <ManageNotice />
        },
        {
          path: 'help',
          element: <ManageHelp />
        }
      ]
    },
    {
      path: 'database',
      children: [
        {
          path: 'patent',
          element: <PatentDatabase />
        }
      ]
    }
  ]
};

export default MainRoutes;
