import * as actionTypes from '../actions';

const initialState = {
  notificationList: [],
  notificationCount: 0
};

const notiReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_NOTI:
      return {
        ...state,
        notificationList: [...state.notificationList, action.notiInfo],
        notificationCount: state.notificationCount + 1
      };
    case actionTypes.READ_NOTI:
      return {
        ...state,
        notificationList: state.notificationList.filter((info) => info !== action.notiInfo),
        notificationCount: state.notificationCount - 1
      };
    default:
      return state;
  }
};

export default notiReducer;
