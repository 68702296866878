// assets
import { IconDatabase } from '@tabler/icons';

// constant
const icons = { IconDatabase };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const database = {
  id: 'database',
  title: 'Database',
  type: 'group',
  children: [
    {
      id: 'patent',
      title: 'Patent',
      type: 'item',
      url: '/database/patent',
      icon: icons.IconDatabase,
      breadcrumbs: false
    }
  ]
};

export default database;
