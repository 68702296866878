// assets
import { IconArticle, IconTools } from '@tabler/icons';

// constant
const icons = {
  IconArticle,
  IconTools
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const contents = {
  id: 'contents',
  title: 'contents',
  type: 'group',
  children: [
    {
      id: 'record',
      title: '레코드',
      type: 'collapse',
      icon: icons.IconArticle,

      children: [
        {
          id: 'record-manage',
          title: '레코드 관리',
          type: 'item',
          url: '/contents/record-manage',
          target: false,
          breadcrumbs: false
        },
        {
          id: 'record-write',
          title: '레코드 쓰기',
          type: 'item',
          url: '/contents/record-write',
          target: false,
          breadcrumbs: false
        },
        {
          id: 'comment-manage',
          title: '댓글 관리',
          type: 'item',
          url: '/contents/comment-manage',
          target: false,
          breadcrumbs: false
        }
      ]
    },
    {
      id: 'ps',
      title: 'P.S.',
      type: 'collapse',
      icon: icons.IconTools,

      children: [
        {
          id: 'ps-manage',
          title: 'P.S. 관리',
          type: 'item',
          url: '/ps/ps-manage',
          target: false,
          breadcrumbs: false
        },
        {
          id: 'ps-write',
          title: 'P.S. 작성',
          type: 'item',
          url: '/ps/ps-write',
          target: false,
          breadcrumbs: false
        }
      ]
    }
  ]
};

export default contents;
